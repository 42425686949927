import { Endpoint } from "../types/MenuTypes";

export const endpoint:Endpoint =  {
auth:'api/auth/',
category:'/api/categories',
scripts:'api/scripts',
reports:'api/reports',
reportschedules:'api/reports/schedules',
search:'api/search?query=',
summaries:"api/summaries"
}